<template>
  <div>

    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>采购退货订单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-input placeholder="请输入内容" v-model="querInfo.pr_code" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-select v-model="querInfo.pr_creator_id" placeholder="请选择退货员" @change="querInfowoagent"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-select v-model="querInfo.pr_payment_status" placeholder="请选择退款状态" @change="querInfocid"
                     clearable class="p_purchase_id" filterable>
            <el-option
                v-for="item in outpayList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-date-picker v-model="querInfo.pr_date" class="p_purchase_id" @change="querInfowodate"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择采购退货日期">
          </el-date-picker>
        </el-col>

        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-button type="primary"  @click="addpurchaseDialog" v-if="is_add" icon="el-icon-document-add">添加</el-button>
          <el-button type="primary"  @click="download_accountsDetails_info" icon="el-icon-download" v-if="is_export">导出</el-button>

        </el-col>
      </el-row>
      <el-table :data="purchaselist" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="订单号" prop="pr_code" show-overflow-tooltip></el-table-column>
        <el-table-column label="采购订单号" prop="p_code" show-overflow-tooltip></el-table-column>
        <el-table-column label="退货员" prop="pr_purchase_name" width="80px"></el-table-column>
        <el-table-column label="采购退货时间" prop="pr_date" show-overflow-tooltip></el-table-column>
        <el-table-column label="采购退货金额" prop="pr_price" show-overflow-tooltip></el-table-column>
        <el-table-column label="采购退货其他金额" prop="pr_other_price" show-overflow-tooltip></el-table-column>
        <el-table-column label="采购退货总计" width="100px">
          <template slot-scope="scope">
            <div style="color: red;font-weight: bold;">{{scope.row.pr_total_price}}</div>
          </template>
        </el-table-column>
<!--        <el-table-column label="状态" prop="pr_status_name" width="80px" show-overflow-tooltip></el-table-column>-->
        <el-table-column label="退款状态" prop="pr_payment_status_name" width="80px"></el-table-column>
        <el-table-column label="出库状态" prop="pr_warehouse_status_name" width="80px"></el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="详情" placement="top" :enterable="true">
              <el-button type="primary" size="mini" icon="el-icon-tickets" @click="PurchaseWarehousingLi(`${scope.row.pr_id}`)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="添加退款记录" placement="top" :enterable="true" v-if="scope.row.p_payment_status!=3&&is_payment">
              <el-button  type="primary" size="mini" icon="el-icon-s-finance"
                          @click="PurchaseWarehousingPay(`${scope.row.pr_id}`)"></el-button>
            </el-tooltip>

            <el-tooltip effect="dark" content="出库" placement="top" :enterable="true" v-if="scope.row.pr_warehouse_status!=3">
              <el-button  type="primary" size="mini" icon="el-icon-sell"
                          @click="PurchaseWarehousingList(`${scope.row.pr_id}`)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="审核" placement="top" :enterable="true" v-if="scope.row.p_status==1&&is_editStatus">
              <el-button  type="info" size="mini" icon="el-icon-s-check"
                          @click="examinePurchaseList(`${scope.row.pr_id}`)"></el-button>
            </el-tooltip>

            <el-button v-if="scope.row.pr_payment_status==1 && is_edit" icon="el-icon-edit"
                       type="primary" size="mini" @click="editPurchaseList(`${scope.row.pr_id}`)"></el-button>
<!--            <el-button type="danger" size="mini" @click="deletePurchaseList(`${scope.row.pr_id}`)" v-if="is_del" icon="el-icon-delete-solid"></el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="提示" :visible.sync="addGoodsDialogVisible"
               width="50%">

      <el-form ref="form" :model="addpurchaseeditStatus">
        <el-form-item label="审核">
          <el-select v-model="addpurchaseeditStatus.pr_status" placeholder="请选择"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in purchaseeditStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="p_remark">
          <el-input v-model="addpurchaseeditStatus.pr_reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoodsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="examineup">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      querInfo: {
        page: 1,
        limit: 10,
        pr_code:''
      },
      //页面权限
      PurchaseJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      is_editStatus:false,
      is_payment:false,
      is_export:false,
      is_logoperation:false,
      is_printing:false,
      //表格加载
      loading:true,
      //采购订单数据列表
      purchaselist: [],
      total: 0,
      addGoodsDialogVisible: false,
      addpurchaseeditStatus: {
        pr_id: '',
        pr_status: ''
      },
      purchaseeditStatus: [{id: 1, name: '待审核'},{id: 2, name: '已审核'},{id: 3, name: '审核失败'}],
      outpayList:[{id:1,name:'未付款'},{id:2,name:'部分付款'},{id:3,name:'已付款'}],
      userlist:[],

    }
  },
  created() {
    this.getPurchaselist()
    // this.getpurchaseeditStatus()
    this.getPurchaseJurisdiction()
    this.getuserList()
  },
  activated(){
    this.getPurchaselist()
    this.getuserList()
  },
  methods: {
    //获取权限接口
    async getPurchaseJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.PurchaseJurisdiction=res.data
      this.PurchaseJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }else if(item.m_path=='editStatus'){
          this.is_editStatus=true
        }else if(item.m_path=='payment'){
          this.is_payment=true
        }else if(item.m_path=='export'){
          this.is_export=true
        }else if(item.m_path=='logoperation'){
          this.is_logoperation=true
        }else if(item.m_path=='printing'){
          this.is_printing=true
        }
      })
    },
    async getPurchaselist() {
      this.loading=true
      const {data: res} = await this.$http.get('purchasereturn/getList',
          {params: this.querInfo})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.purchaselist = res.data.data
      this.total = res.data.total
      this.loading=false
    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getPurchaselist()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getPurchaselist()
    },
    //退货员 查询
    querInfowoagent(){
      this.querInfo.page = 1
      this.getPurchaselist()
    },
    //退款状态 查询
    querInfocid(){
      this.querInfo.page = 1
      this.getPurchaselist()
    },
    //采购退货日期 查询
    querInfowodate(){
      this.querInfo.page = 1
      this.getPurchaselist()
    },
    inputchange(){
      this.querInfo.page=1
      this.getPurchaselist()
    },
    //
    addpurchaseDialog() {
      this.$router.push('/purchasereturn/addpurchasereturn')

    },
    editPurchaseList(id) {
      this.$router.push({path: "/purchasereturn/editpurchasereturn", query: {editid: id}})
    },
    //删除 qualitylevel/del/12
    async deletePurchaseList(id) {
      const confirmResult = await this.$confirm(
          '此操作将永久删除该采购订单, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data: res} = await this.$http.post(`purchasereturn/del/${id}`)
      if (res.code != 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getPurchaselist()

    },
    examinePurchaseList(id) {
      this.addGoodsDialogVisible = true
      this.addpurchaseeditStatus.p_id = id
    },
    PurchaseWarehousingList(id){
      this.$router.push({path: "/purchasereturn/returnpurchasewarehouseout", query: {editid: id}})
    },
    PurchaseWarehousingLi(id){
      this.$router.push({path: "/purchasereturn/purchasereturnDetails", query: {editid: id,is_printing:this.is_printing}})
    },
    PurchaseWarehousingPay(id){
      this.$router.push({path: "/purchasereturn/purchasereturnPay", query: {editid: id}})
    },
    async getpurchaseeditStatus() {
      console.log(this.id)
      const {data: res} = await this.$http.get('purchase/getStatusList')
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.purchaseeditStatus = res.data
    },
    async examineup() {
      const {data: res} = await this.$http.post('purchase/editStatus', this.addpurchaseeditStatus)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.addGoodsDialogVisible = false
      this.addpurchaseeditStatus.p_id = ''
      this.getPurchaselist()
    },
    //下载
    // async DownloadpurchaseDialog(){
    //   const {data: res} = await this.$http.get('goods/export')
    //   // if (res.code !== 200) return this.$message.error("失败")
    //   // this.$message.success('成功')
    //   // 处理返回的文件流
    //   let blob = new Blob([res], {
    //     type: "application/vnd.ms-excel"
    //   }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
    //   console.log(window.URL.createObjectURL(blob))
    //   let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
    //
    //   // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
    //   let a = document.createElement("a");
    //   a.href = url;
    //   // a.download = "导出表格.xlsx";
    //   a.click();
    //   // 5.释放这个临时的对象url
    //   window.URL.revokeObjectURL(url);
    // }
    async download_accountsDetails_info() {
      return new Promise((resolve, reject) => {
        // console.log(resolve)
        // console.log(reject)
        const {data:res} = this.$http.get('purchasereturn/export', {
          responseType: "blob" // 1.首先设置responseType对象格式为 blob:
        })
            .then(
                res => {
                  //resolve(res)
                  let blob = new Blob([res.data], {
                    type: "application/vnd.ms-excel"
                  }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
                  let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
                  // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
                  let a = document.createElement("a");
                  var date = new Date();
                  a.href = url;
                  a.download = "采购退货订单"+date.getFullYear()+"-"+ (date.getMonth()+1) +"-"+date.getDate() +".xlsx";
                  a.click();
                  // 5.释放这个临时的对象url
                  window.URL.revokeObjectURL(url);
                },
                err => {
                  resolve(err.response);
                }
            )
            .catch(error => {
              reject(error);
            });
      });
    },
  }
}
</script>
<style>
.p_purchase_id{
  width: 100%;
}

.el-date-editor.el-input{
  width: 100%;

}
</style>
